<template>
    <div class="snow-wrapper">
        <span v-for="index in numberOfSnowflakes" :key="index" class="far fa-snowflake snowflake"></span>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            numberOfSnowflakes: 0,
        }
    },
    methods: {
        randomNumber: function() {
            this.numberOfSnowflakes = Math.floor((Math.random() * 10) + 1);
        },
    },
    mounted: function() {
        this.randomNumber();
    }
}
</script>