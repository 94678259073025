<template>
    <div class="row h-100">
        <div class="d-flex flex-column justify-content-center align-items-start">
            <h1 class="display-1 d-inline-block">Dean Tracey</h1>
            <a :href="`mailto:${email}`" class="btn btn-dark">
                <span class="far fa-envelope"></span>
                Let's chat
            </a>
        </div>      
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                email: 'mail@deantracey.net',
            }
        },
        computed: {

        },
        methods: {

        },
        mounted: function() {

        }
    };
</script>