import Vue from "vue";
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

import MainApp from './../../components/MainApp.vue';
import WavesComponent from './../../components/WavesComponent.vue';
import SnowComponent from './../../components/SnowComponent.vue';

// Setup Vue
new Vue({
    el: '#app',
    data: function() {
        return {

        }
    },
    mounted: function() {

    },
    components: { MainApp, WavesComponent, SnowComponent }
});